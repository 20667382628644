import axios from 'axios'
import qs from 'qs'
import AES from '@/utils/AES'
import ASCII from '@/utils/setASCII'
//import { MessageBox, Message } from 'element-ui'
// import store from '@/store'
// import { getToken } from '@/utils/auth'
//获取当前时间戳
function getNowTime() {
  let time = parseInt(new Date().getTime() / 1000) + '';
  return time
}
//截取前3后5时间戳
function timeStamp(time) {
  let timestamp = time.substring(0, 3) + time.substring(time.length - 5, time.length)
  return timestamp
}
//ASCII排序
function set_ASCII(data) {
  return ASCII.setASCII(data)
}
//aes加密
function aes(data) {
  return AES.encrypt(data)
}
//生成16位随机字符串
function createNonceStr() {
  let chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
  let nums = "";
  for (let i = 0; i < 16; i++) {//这里是几位就要在这里不改变
    let id = parseInt(Math.random() * 61);
    nums += chars[id];
  }
  return nums;
}
//判断机型
function judgeClient() {
  let u = navigator.userAgent;
  let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;   //判断是否是 android终端
  let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);     //判断是否是 iOS终端
  if (isAndroid) {
    return 1
  } else {
    return 2
  }
}
// create an axios instance
const service = axios.create({
  // baseURL: 'http://47.98.108.80:9503/',//测试服
  baseURL:'https://api.wuhanshuiningkeji.cn/',//正式服
  // baseURL: 'https://pro.ximantianwangluo.cn', // 正式服
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000, // request timeout
  headers: {
    'Content-Type': 'application/json'
  }
})
// request interceptor
service.interceptors.request.use(
  config => {
    const token = localStorage.getItem('access_token')
    let data = qs.parse(config.data)
    data.timestamp = timeStamp(getNowTime())
    data.version = '3.0.1'
    data.mobile_type = getNowTime()
    data.nonce_str = createNonceStr()
    data.long = '0'
    data.lat = '0'
    data.i_mei = createNonceStr()
    data.system_type = judgeClient()
    if(token){
      data.token=token
    }
    // console.log(data)
    let a = set_ASCII(data)
    // console.log(a)
    let d = aes(a)
    data.timestamp = getNowTime()
    data.sign = d
    console.log(data)
    if (token) {
      if (config.method === 'post') {
        config.data = qs.stringify({
          token: token,
          ...data
        })
        config.headers = {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }else{
         config.params=data
         config.headers = {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    }else{
      if (config.method === 'post') {
        config.data = qs.stringify({
          ...data
        })
        config.headers = {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    }


    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response
    // if the custom code is not 200, it is judged as an error.
    // console.log(res)
    if (res.data.code == 10000) {
      return res.data
    } else {
       if(res.data.code==10017){
        localStorage.removeItem('access_token')
        this.$router.push({
          path: "/phoneLogin",
        });
        return Promise.reject('登录已过期，请前往app重新登录')
       }
      return Promise.reject(res.data.msg)
      // this.Toast(res.data.msg)
    }
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
